import Alpine from "alpinejs";
import prettyBytes from "pretty-bytes";

let map;
let view
let graphicsLayer
let sketch

const featureLayers = {}
const layerViews = {}

const createLayerData = (layerId, name, color) => {
    return {
        name,
        layerId,
        objectIds: [],
        totalSize: null,
        loading: false,
        color,
    }
}

export default ({
    scale,
    center,
    mode,
    showAerial,
    layers,
    years,
    selectedTagFilter,
    tagFilterValue,
    filteredDatasetIds,
    datasetIds,
    ignoredDatasetIds,
}) => ({
    scale,
    center,
    mode,
    showAerial,
    layers,
    layersData: [
        createLayerData('nadir', 'Nadir', [138, 95, 22, .1]),
        createLayerData('oblique', 'Oblique', [26, 145, 32, .1]),
        createLayerData('ortho', 'Ortho', [72, 136, 92, .1]),
        createLayerData('panorama', 'Panorama', [255, 130, 0, .5]),
        createLayerData('pointcloud', 'Pointcloud', [170, 46, 219, .1]),
    ],
    layersDisplayNames: {
        Nadir: 'Nadir luchtfoto',
        Oblique: 'Obliek luchtfoto',
        Ortho: 'Orthofoto',
        Panorama: 'Panoramafoto',
        Pointcloud: 'Puntenwolk',
    },
    years,
    selectedTagFilter,
    tagFilterValue,
    filteredDatasetIds,
    datasetIds,
    ignoredDatasetIds,
    showDownloadModal: false,
    scaleThreshold: 48000,
    manualSelection: null,

    init() {
        require([
            "esri/Map",
            "esri/views/MapView",
            "esri/layers/FeatureLayer",
            "esri/layers/MapImageLayer",
            "esri/geometry/Point",
        ], (
            Map,
            MapView,
            FeatureLayer,
            MapImageLayer,
            Point,
        ) => {
            map = new Map({
                basemap: {
                    portalItem: {
                        id: "c8cb478cd4da4206a56e2a2fba545ccf" // Topo RD vector tiled
                    },
                },
            })

            view = new MapView({
                container: this.$refs['map'],
                map: map,
                center: new Point({
                    x: this.center[0],
                    y: this.center[1],
                    spatialReference: {
                        wkid: 28992,
                    },
                }),
                scale: this.scale,
            })

            view.on("pointer-move", (event) => {
                let point = view.toMap({ x: event.x, y: event.y })
                this.$refs['coordinates'].innerText = `X ${Math.round(point.x, 3)} Y ${Math.round(point.y, 3)}`
            })
            view.ui.add(this.$refs['coordinates'], "bottom-left");

            view.watch('center', (newValue) => {
                this.center = [newValue.x, newValue.y]
            })
            view.watch('scale', (newValue) => {
                this.scale = newValue
            })

            const prorailBasiskaartUrl = 'https://maps.prorail.nl/arcgis/rest/services/ProRail_basiskaart/FeatureServer'
            const prorailLayers = {
                Luchtfoto: new MapImageLayer({ url: 'https://luchtfoto.prorail.nl/erdas-iws/esri/Luchtfoto/rest/services/meest_recent/MapServer', visible: false }),
                // Overweg: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 0 }),
                Station: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 1 }),
                // Dienstregelpunt : new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 2 }),
                'Hectometerpunt (geocode)': new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 3 }),
                Hectometerraai: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 4 }),
                Hectometerraailabel: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 5 }),
                'Spoorbaanhartlijn (geocode)': new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 6 }),
                Wissel: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 7 }),
                // Kruising: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 8 }),
                Spoortakdeel: new FeatureLayer({ url: prorailBasiskaartUrl, layerId: 9 }),
            }

            this.$watch('showAerial', (shouldShow) => {
                prorailLayers.Luchtfoto.visible = shouldShow
            })

            Object.entries(prorailLayers).forEach(([name, featureLayer]) => {
                map.add(featureLayer)
            })

            require([
                "esri/widgets/Search",
            ], (
                Search,
            ) => {
                const searchWidget = new Search({
                    view: view,
                    includeDefaultSources: true,
                    sources: [
                        {
                            layer: prorailLayers["Hectometerpunt (geocode)"],
                            name: "Geocode", // alleen zoeken op geocode
                            searchFields: ["GEOCODE"],
                            displayField: "GEOCODE",
                            suggestionTemplate: "{GEOCODE} - {GEOCODE_NAAM}",
                            zoomScale: 50000,
                        },
                        {
                            layer: prorailLayers["Hectometerpunt (geocode)"],
                            name: "Geocode - km",
                            searchFields: ["GEOKMT"],
                            displayField: "GEOKMT",
                            zoomScale: 1000,
                        },
                        {
                            layer: prorailLayers["Station"],
                            name: "Stations",
                            searchFields: ["AFKORTING", "NAAM"],
                            displayField: "NAAM",
                            zoomScale: 1200,
                        },
                        // ks blad
                        // geul
                        // wissel
                        // sein
                    ],
                });
                view.ui.add(searchWidget, {
                    position: "top-right",
                })
                // searchWidget.on("select-result", event => {
                //     console.log(JSON.stringify(event.result))
                //     view.extent = event.result.extent
                // })
            })

            require([
                "esri/layers/GraphicsLayer",
                "esri/widgets/Sketch",
            ], (
                GraphicsLayer,
                Sketch,
            ) => {
                graphicsLayer = new GraphicsLayer()
                map.add(graphicsLayer)
                sketch = new Sketch({
                    layer: graphicsLayer,
                    view: view,
                    creationMode: 'single',
                    availableCreateTools: ["polygon"],
                    visibleElements: {
                        selectionTools: {
                            "lasso-selection": false,
                        },
                        undoRedoMenu: false,
                        settingsMenu: false,
                    },
                })
                view.ui.add(sketch, "bottom-right")

                sketch.on('delete', event => {
                    this.manualSelection = null
                })

                sketch.on('update', event => {
                    this.manualSelection = {
                        type: 'polygon',
                        ...JSON.parse(JSON.stringify(event.graphics[0].geometry)),
                    }
                })

                sketch.on("create", event => {
                    if (event.state === 'complete') {
                        if (graphicsLayer.graphics.length > 1) {
                            graphicsLayer.graphics.removeAt(0) // remove previous polygon
                        }

                        this.manualSelection = {
                            type: 'polygon',
                            ...JSON.parse(JSON.stringify(event.graphic.geometry)),
                        }
                    }
                })

                this.$watch('manualSelection', Alpine.throttle(() => {
                    // console.log('manual selection changed, triggering throttled updateQueryResultsForLayer')
                    this.layers.forEach(layer => {
                        this.updateQueryResultsForLayer(layer)
                    })
                }, 300))

                this.$watch('ignoredDatasetIds', Alpine.throttle(() => {
                    // console.log('manual selection changed, triggering throttled updateQueryResultsForLayer')
                    this.layers.forEach(layer => {
                        this.updateQueryResultsForLayer(layer)
                    })
                }, 300))
            })

            this.modeChanged() // initial load

            this.$watch('mode', (value) => {
                // console.log('mode watcher fired')
                // this.modeChanged() // also does initial load

                this.layers.forEach(layer => {
                    this.updateQueryResultsForLayer(layer)
                })
            })
        })
    },

    modeChanged() {
        require([
            "esri/layers/OGCFeatureLayer"
        ], (
            OGCFeatureLayer,
        ) => {
            this.layers.forEach((layer, index) => {
                const layerData = this.layersData.find(ld => ld.name === layer.name)

                if (featureLayers[layer.name]) {
                    map.remove(featureLayers[layer.name])
                    delete featureLayers[layer.name]
                }

                const featureLayer = new OGCFeatureLayer({
                    url: 'https://api.spoorinbeeld.nl/api/ogc',
                    collectionId: layerData.layerId,
                    minScale: this.scaleThreshold,
                    maxScale: 250,
                    visible: layer.visible,
                    renderer: {
                        type: "simple",
                        symbol: {
                            type: layerData.layerId === 'panorama' ? "simple-marker" : "simple-fill",
                            color: layerData.color,
                            outline: {
                                width: 0.5,
                                color: [layerData.color[0], layerData.color[1], layerData.color[2], 0.7],
                            },
                        }
                    }
                })
                map.add(featureLayer)
                featureLayers[layer.name] = featureLayer

                view.whenLayerView(featureLayer).then((layerView) => {
                    layerViews[layer.name] = layerView

                    // set initial filter value
                    if (layer.visible) {
                        layerView.filter = {
                            where: this.layerFilter,
                        }
                    }

                    // This is already pretry much debounced.
                    // Sometimes it has 1 or more execution with `val`, but always only one without `val`.
                    layerView.watch("updating", val => {
                        // console.log(`layerView ${layer.name} updated, val:${val}`)
                        if (!val && !this.manualSelection) {
                            // console.log('layerView updated, triggering layer updateQueryResultsForLayer')
                            this.updateQueryResultsForLayer(layer)
                        }
                    })

                    // when layer.visible changes, we need to update the featureLayer
                    // when layerFilter changes, we need to update the layerView

                    this.$watch('layerFilter', newValue => {
                        // console.log(`[${layer.name}] layerFilter changed, triggering layer updateQueryResultsForLayer`)

                        // we only need to set the filter then the layer is actually visible
                        if (layer.visible) {
                            layerView.filter = {
                                where: newValue,
                            }
                        }
                    })

                    this.$watch(`layers[${index}].visible`, (newValue, oldValue) => {
                        if (newValue === oldValue) return

                        // console.log(`[${layer.name}] layer.visible changed to ${newValue}, triggering layer updateQueryResultsForLayer`)

                        // if (! newValue) {
                        //     // When a featureLayer becomes visible, its layerView dispatches the `updating` event
                        //     // This is not the case when it becomes hidden, so we need to trigger it manually
                        //     this.updateQueryResultsForLayer(layer, false)
                        // }

                        this.updateQueryResultsForLayer(layer, newValue)

                        featureLayer.visible = newValue

                        // this.updateQueryResultsForLayer(layer, newValue)
                    })
                })
            })

            // make sure the graphicsLayer always draws on top
            map.reorder(graphicsLayer, 99)
        })
    },

    get layerFilter() {
        const filters = []

        const activeYears = this.years.filter(y => y.active)
        if (activeYears.length) {
            filters.push(
                activeYears.map(y => `(date >= '${y.year}-01-01' AND date < '${y.year}-12-31')`).join(' OR ')
            )
        } else {
            filters.push(
              `(date < '1970-01-01')` // hide all data
            )
        }

        if (this.filteredDatasetIds !== null) {
            filters.push(
                this.filteredDatasetIds.length
                    ? this.filteredDatasetIds.map(id => `run_id = ${id}`).join(' OR ')
                    : `run_id = -1`
            )
        }

        return filters.map(filter => `(${filter})`).join(' AND ')
    },

    get uniqueDatasetIds() {
        return [...new Set(Object.values(this.datasetIds).flat())].sort()
    },

    get totalDownloadSize() {
        const sizes = Object.values(this.layersData).map(l => l.totalSize).filter(x => x)
        if (sizes.length) {
            return sizes.reduce((sum, x) => sum + x)
        }

        return 0
    },

    get totalObjectIds() {
        return this.layersData.flatMap(layerData => layerData.objectIds).length
    },

    get anyLayerIsLoading() {
        return this.layersData.filter(layerData => layerData.loading).length !== 0
    },

    async updateQueryResultsForLayer(layer, visible = null) {
        const layerData = this.layersData.find(ld => ld.name === layer.name)

        visible ??= layer.visible

        // console.log(`updateQueryResultsForLayer ${layer.name} (visibility: ${visible})`)
        if (!visible || this.scale > this.scaleThreshold) {
            layerData.objectIds = []
            layerData.totalSize = 0
            this.datasetIds[layer.name] = []

            return
        }

        const featureLayerView = layerViews[layer.name]

        layerData.loading = true

        const datasetIdsResult = await featureLayerView.queryFeatures({
            geometry: this.manualSelection ?? view.extent,
            where: this.layerFilter,
            outFields: ['run_id'],
            returnDistinctValues: true,
            returnGeometry: false,
        })

        this.datasetIds[layer.name] = [...datasetIdsResult.features.map(f => f.attributes.run_id)]

        if (this.mode == 'download') {
            // we now need to exclude the exludeddatasetIds
            const filter = this.ignoredDatasetIds.length
                ? `${this.layerFilter} AND run_id NOT IN (${this.ignoredDatasetIds.join(',')})`
                : this.layerFilter

            // console.log(filter)

            const fileSizeResult = await featureLayerView.queryFeatures({
                geometry: this.manualSelection ?? view.extent,
                where: filter,
                outFields: ['OBJECTID', 'filesize'],
                returnGeometry: false,
            })

            layerData.objectIds = [...fileSizeResult.features.map(f => f.attributes.OBJECTID)]
            layerData.totalSize = fileSizeResult.features.reduce(
                (sum, f) => sum + f.attributes.filesize,
                0
            )
        } else {
            layerData.objectIds = []
            layerData.totalSize = 0
        }

        layerData.loading = false
    },

    formatMegabytes(megabytes) {
        const bytes = megabytes * 1000 * 1000

        return prettyBytes(bytes, { locale: 'nl' })
    },

    async launchViewer() {
        const viewerWindow = window.open('', '_blank')
        viewerWindow.document.write('Loading viewer...')
        viewerWindow.blur()

        try {
            const viewerUrl = await this.$wire.createDynamicProject(this.uniqueDatasetIds)
            viewerWindow.location.href = viewerUrl
            viewerWindow.focus()
            // window.open(viewerUrl, '_blank')
            // window.location.href = viewerUrl
        } catch (e) {
            viewerWindow.close()
            console.error(e)
        }
    },

    async requestDownload() {
        const collections = this.layersData.reduce((a, v) => ({ ...a, [v.name]: v.objectIds }), {})
        await this.$wire.requestDownload(collections)
    },

    setIgnoredDatasetId(datasetId, ignored) {
        const localCopy = this.ignoredDatasetIds

        if (ignored) {
            // ensure its in there
            if (!localCopy.includes(datasetId)) {
                localCopy.push(datasetId)
                this.ignoredDatasetIds = localCopy
            }
        } else {
            // ensure its NOT in there
            if (localCopy.includes(datasetId)) {
                localCopy.splice(
                    localCopy.indexOf(datasetId),
                    1,
                )
                this.ignoredDatasetIds = localCopy
            }
        }
    },
})
