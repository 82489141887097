export default ({ value }) => ({
    wasRecentlyClicked: false,

    trigger: {
        ['@click']() {
            this.$clipboard(value)

            this.wasRecentlyClicked = true

            setTimeout(() => {
                this.wasRecentlyClicked = false
            }, 3000)
        },
    },

    pendingState: {
        ['x-show']() {
            return this.wasRecentlyClicked === false
        },
    },

    successState: {
        ['x-show']() {
            return this.wasRecentlyClicked === true
        },
    },
})
