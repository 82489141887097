import { Dropzone } from 'dropzone'

Dropzone.autoDiscover = false

export default ({ action, formDataOverride, keyPrefix }) => ({
    isUploading: false,
    dropzoneInstance: null,

    init() {
        this.dropzoneInstance = new Dropzone(this.$refs.dropzone, {
            url: action,
            autoQueue: true,
            addRemoveLinks: true,
            acceptedFiles: 'image/jpeg',
            parallelUploads: 5,
            createImageThumbnails: true,
        });

        this.dropzoneInstance.on('sending', (file, xhr, formData) => {
            const ourFormData = formDataOverride;
            const prefix = keyPrefix;

            for (const [key, value] of Object.entries(ourFormData)) {
                formData.set(key, value)
            }

            formData.set('Content-Type', file.type)
            formData.set('key', `${prefix}/${file.name}`)
        });

        this.dropzoneInstance.on('queuecomplete', () => {
            this.isUploading = false;
        });
    }
})
