import Uppy from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import StatusBar from "@uppy/status-bar";
import AwsS3 from "@uppy/aws-s3";

import "@uppy/core/dist/style.min.css";
import "@uppy/drag-drop/dist/style.min.css";
import "@uppy/status-bar/dist/style.min.css";

export default ({ allowedFileTypes, prefix, isUploading }) => ({
    isUploading: isUploading,

    init() {
        const companionBaseUrl = `${location.origin}${location.pathname}/companion`;
        const uppy = new Uppy({
            id: prefix,
            autoProceed: true,
            debug: true,
            restrictions: allowedFileTypes
                ? {
                      allowedFileTypes: allowedFileTypes,
                  }
                : null,
        })
            .use(DragDrop, { target: this.$refs.uppy })
            .use(StatusBar, { target: this.$refs["uppy-progress"] })
            .use(AwsS3, {
                shouldUseMultipart: (file) => file.size > 100 * 2 ** 20, // 100MiB
                companionUrl: companionBaseUrl,
                companionHeaders: {
                    "x-georizon-uppy-prefix": prefix,
                },
            });

        uppy.on("files-added", (result) => {
            this.isUploading = true;
        });

        uppy.on("complete", (result) => {
            this.isUploading = false;
        });

        uppy.on("cancel-all", (result) => {
            this.isUploading = false;
        });
    },
});
